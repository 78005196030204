import { createGlobalStyle } from 'styled-components';

export const NavbarTabs = createGlobalStyle`
  .logoWrapper div[class*="paradime-tab"] {
    height: 57px;
  }

  .logoWrapper div[class*="paradime-tab"] > div[class*="tab-list"] {
    height: 57px;
  }

  .logoWrapper div[class*="paradime-tab"] > div[class*="tab-list"] > div[class*="tab-indicator-wrapper"] {
    height: 57px !important;
  }

  .logoWrapper div[class*="paradime-tab"] > div[class*="tab-list"] > div[class*="enabled-tab"] {
    height: 57px;
  }

`;
