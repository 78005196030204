import { GqlParadimeAccountType } from '../client/generated/service';

// Naming convention to be followed - userHas<context><feature>Access (always positive)

const PARADIME_ACCOUNT_TYPES_WITHOUT_UNKNOWN = Object.values(GqlParadimeAccountType).filter((accType) => accType !== 'UNKNOWN');

const hasAccess = (rolesWithAccess: GqlParadimeAccountType[],
  role: GqlParadimeAccountType): boolean => rolesWithAccess.includes(role);

const userHasExtensionAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.ExtensionAdmin,
  GqlParadimeAccountType.ExtensionBusiness,
], role);

const userHasAccessLevelSet = (role: GqlParadimeAccountType)
  : boolean => hasAccess(PARADIME_ACCOUNT_TYPES_WITHOUT_UNKNOWN, role);

const userHasSandboxAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([GqlParadimeAccountType.SandboxUser], role);

const userHasEditorAdminAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasEditorAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasBoltAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasBoltCreateScheduleAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
], role);

const userHasBoltTriggerScheduleAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasBoltCancelScheduleAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasBoltEditAndCopyScheduleAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
], role);

const userHasBoltAdminAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasBoltThoughtspotDbtSyncAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
], role);

const userHasLineageAdminAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasLineageAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasLineageCompareAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasLineageSearchAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasLineageContextMenuAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasCatalogAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasCatalogDevModeAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasCatalogProdModeAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasCatalogAdminAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasRadarAdminAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasRadarAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasRadarCustomDashboardEditAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasRadarAddCostConnectionAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
], role);

const userHasRadarAddGithubConnectionAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
], role);

const userHasRadarCostOptimisationsEditAccess = (role: GqlParadimeAccountType): boolean => (
  hasAccess([
    GqlParadimeAccountType.Admin,
    GqlParadimeAccountType.WorkspaceAdmin,
    GqlParadimeAccountType.Developer,
    GqlParadimeAccountType.SandboxUser,
  ], role)
);

const userHasWorkbenchAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasPlatformWorkspaceCreateAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasPlatformPlansUpdateAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.BillingAdmin,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasPlatformBillingUpdateAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.BillingAdmin,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasPlatformAuditLogsAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.SecurityAdmin,
], role);

const userHasPlatformInsightsAccess = (role: GqlParadimeAccountType): boolean => hasAccess([
  GqlParadimeAccountType.Admin,
], role);

const userHasPlatformProfileSettingsPrivacyAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.BillingAdmin,
  GqlParadimeAccountType.SecurityAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasPlatformProfileSettingsEnvVarsAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasPlatformProfileSettingsGithubAuthAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
], role);

const userHasAccountSettingsAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
  GqlParadimeAccountType.SecurityAdmin,
], role);

const userHasAccountSettingsConnectionsAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasAccountSettingsConnectionsAdminAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsConnectionsDevAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasAccountSettingsConnectionsDevUpdateAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
], role);

const userHasAccountSettingsConnectionsProdAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasAccountSettingsConnectionsProdUpdateAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsConnectionsCostAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasAccountSettingsConnectionsCostUpdateAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsWorkspacesAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasAccountSettingsWorkspacesManageAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsWorkspacesDbtVersionUpdateAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsWorkspacesEnvVarsUpdateAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsWorkspacesGitRepoUpdateAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsWorkspacesApiKeysAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsWebhooksAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
], role);

const userHasAccountSettingsIntegrationsAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasAccountSettingsIntegrationsUpdateAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsTeamsAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasAccountSettingsTeamsAutoJoinUpdateAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsTeamsInviteUserAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.Developer,
  GqlParadimeAccountType.Analyst,
  GqlParadimeAccountType.Business,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasAccountSettingsTeamsUpdateUserAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsTeamsAdminAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasAccountSettingsNotificationsEditAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
], role);

const userHasSlackAdminAccess = (role: GqlParadimeAccountType)
  : boolean => hasAccess([
  GqlParadimeAccountType.Admin,
  GqlParadimeAccountType.WorkspaceSettingsAdmin,
  GqlParadimeAccountType.WorkspaceAdmin,
  GqlParadimeAccountType.SandboxUser,
], role);

const userHasAccountSettingsEnvVarsAccess = (role: GqlParadimeAccountType)
  : boolean => (
  userHasAccountSettingsWorkspacesEnvVarsUpdateAccess(role)
  || userHasPlatformProfileSettingsEnvVarsAccess(role)
);

export {
  PARADIME_ACCOUNT_TYPES_WITHOUT_UNKNOWN,
  hasAccess,
  userHasExtensionAccess,
  userHasAccessLevelSet,
  userHasSandboxAccess,
  userHasEditorAdminAccess,
  userHasEditorAccess,
  userHasBoltAccess,
  userHasBoltCreateScheduleAccess,
  userHasBoltTriggerScheduleAccess,
  userHasBoltCancelScheduleAccess,
  userHasBoltEditAndCopyScheduleAccess,
  userHasBoltAdminAccess,
  userHasBoltThoughtspotDbtSyncAccess,
  userHasLineageAdminAccess,
  userHasLineageAccess,
  userHasLineageCompareAccess,
  userHasLineageSearchAccess,
  userHasLineageContextMenuAccess,
  userHasCatalogAccess,
  userHasCatalogDevModeAccess,
  userHasCatalogProdModeAccess,
  userHasCatalogAdminAccess,
  userHasRadarAdminAccess,
  userHasRadarAccess,
  userHasRadarCustomDashboardEditAccess,
  userHasRadarAddCostConnectionAccess,
  userHasRadarAddGithubConnectionAccess,
  userHasRadarCostOptimisationsEditAccess,
  userHasWorkbenchAccess,
  userHasPlatformWorkspaceCreateAccess,
  userHasPlatformPlansUpdateAccess,
  userHasPlatformBillingUpdateAccess,
  userHasPlatformAuditLogsAccess,
  userHasPlatformInsightsAccess,
  userHasPlatformProfileSettingsPrivacyAccess,
  userHasPlatformProfileSettingsEnvVarsAccess,
  userHasPlatformProfileSettingsGithubAuthAccess,
  userHasAccountSettingsAccess,
  userHasAccountSettingsConnectionsAccess,
  userHasAccountSettingsConnectionsAdminAccess,
  userHasAccountSettingsConnectionsDevAccess,
  userHasAccountSettingsConnectionsDevUpdateAccess,
  userHasAccountSettingsConnectionsProdAccess,
  userHasAccountSettingsConnectionsProdUpdateAccess,
  userHasAccountSettingsConnectionsCostAccess,
  userHasAccountSettingsConnectionsCostUpdateAccess,
  userHasAccountSettingsWorkspacesAccess,
  userHasAccountSettingsWorkspacesManageAccess,
  userHasAccountSettingsWorkspacesDbtVersionUpdateAccess,
  userHasAccountSettingsEnvVarsAccess,
  userHasAccountSettingsWorkspacesEnvVarsUpdateAccess,
  userHasAccountSettingsWorkspacesGitRepoUpdateAccess,
  userHasAccountSettingsWorkspacesApiKeysAccess,
  userHasAccountSettingsWebhooksAccess,
  userHasAccountSettingsIntegrationsAccess,
  userHasAccountSettingsIntegrationsUpdateAccess,
  userHasAccountSettingsTeamsAccess,
  userHasAccountSettingsTeamsAutoJoinUpdateAccess,
  userHasAccountSettingsTeamsInviteUserAccess,
  userHasAccountSettingsTeamsUpdateUserAccess,
  userHasAccountSettingsTeamsAdminAccess,
  userHasAccountSettingsNotificationsEditAccess,
  userHasSlackAdminAccess,
};
