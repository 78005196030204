import React, { FC, useEffect, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { companyStore, userAuthStore } from '../../stores';
import BookDemo from '../Common/Libraries/Cal';

const CalloutSandboxWorkspace: FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const history = useHistory();

  const { isSandboxWorkspace } = userAuthStore((state) => state.currentUser);
  const companyPlan = companyStore((state) => state.companyPlan);

  const isPlansPage = window.location.pathname === '/settings/plans';
  const isWorkspacesPage = window.location.pathname === '/settings/workspaces';

  useEffect(() => {
    const calculateVisibility = () => {
      if (!isSandboxWorkspace) return false;
      if (companyPlan?.hasPricingPlan && isWorkspacesPage) return false;
      if (!companyPlan?.hasPricingPlan && isPlansPage) return false;
      return true;
    };

    setIsVisible(calculateVisibility());
  }, [isSandboxWorkspace, isPlansPage]);

  if (!isVisible) return null;

  return (
    <div
      style={{
        backgroundColor: 'var(--pale-violet)',
        width: '100%',
        height: '44px',
        padding: '8px 16px',
      }}
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="space-between"
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="packed"
          horizontalGap="very-dense"
        >
          <Icon icon="data-connection" size={20} color="var(--violet70)" />
          <Typography
            font="inter"
            type="body-bold-small"
            color="primary"
            colorStep="70"
          >
            This is a demo workspace
          </Typography>
          <Typography
            font="inter"
            type="caption"
            color="primary"
            colorStep="70"
          >
            {companyPlan?.hasPricingPlan
              ? 'Switch to your workspace.'
              : 'Upgrade to connect your own data.'}
          </Typography>
        </AutoLayout>
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="packed"
          horizontalGap="very-dense"
        >
          {companyPlan?.hasPricingPlan
            ? (
              <DefaultButton
                type="standard"
                view="gradient-primary"
                dense
                text="Switch workspace"
                onClick={() => history.push('/settings/workspaces')}
                eventContext={Contexts.APP_NAV_BAR}
                eventObject="switchWorkspace"
                eventAction={Actions.CLICKED}
              />
            )
            : (
              <AutoLayout
                direction="horizontal"
                distribution="packed"
                padding="none"
                horizontalGap="very-dense"
              >
                <BookDemo
                  cta="Book walkthrough"
                  bookingLink="kaustav/paradime-intro"
                  embedType="popup"
                />
                <DefaultButton
                  type="standard"
                  view="gradient-primary"
                  dense
                  icon="rocket-slant"
                  text="Upgrade"
                  onClick={() => history.push('/settings/plans')}
                  eventContext={Contexts.APP_NAV_BAR}
                  eventObject="viewPlans"
                  eventAction={Actions.CLICKED}
                />
              </AutoLayout>
            )}
          <Icon icon="cross" color="var(--grey50)" size={20} onClick={() => setIsVisible(false)} style={{ cursor: 'pointer' }} />
        </AutoLayout>
      </AutoLayout>
    </div>
  );
};

export default CalloutSandboxWorkspace;
