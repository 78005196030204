import React, { FC, useState } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import TabsDefault from '@paradime-io/pragma-ui-kit/lib/components/TabsDefault';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useGetTimelineData } from '../../hooks/useGetTimelineData';
import { GetScheduleRunQuery } from '../../../../client/generated/service';
import TimelineView from './TimelineView';
import DAG from './DAG';

export enum InsightTabIds {
  TIMELINE_VIEW = 'timeline',
  DAG_VIEW = 'dag',
}

const insightTabsData = [
  { id: InsightTabIds.DAG_VIEW, title: 'DAGs', visible: true },
  { id: InsightTabIds.TIMELINE_VIEW, title: 'Model Timeline', visible: true },
];

export interface InsightsProps {
  isRunning?: boolean,
  runId: number,
  shouldFetchAnalytics: boolean,
  endDttm?: string | null,
  runState: string,
  runData?: GetScheduleRunQuery,
}

const Insights:FC<InsightsProps> = ({
  isRunning,
  runId,
  endDttm,
  runState,
  runData,
}) => {
  const [selectedTabId, setSelectedTabId] = useState<string>(InsightTabIds.DAG_VIEW);

  const {
    timelineView,
    isLoading,
    deadlinePassed,
  } = useGetTimelineData(runId, endDttm);

  const getTabView = () => {
    switch (selectedTabId) {
      case InsightTabIds.TIMELINE_VIEW:
        return (
          <TimelineView
            timelineView={timelineView}
            isLoading={isLoading}
            deadlinePassed={deadlinePassed}
            isRunning={isRunning}
            endDttm={endDttm}
            runState={runState}
          />
        );
      case InsightTabIds.DAG_VIEW:
        return (
          <DAG
            scheduleMeta={runData?.getScheduleRun?.meta}
            commands={runData?.getScheduleRun?.commands}
          />
        );
      default:
        return (
          <DAG
            scheduleMeta={runData?.getScheduleRun?.meta}
            commands={runData?.getScheduleRun?.commands}
          />
        );
    }
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="dense"
      alignment="top-left"
      style={{ marginTop: '40px' }}
    >
      <Typography type="h6" tagName="span">Insights</Typography>
      <TabsDefault
        type="dense"
        view="flat"
        color="default"
        fill={false}
        data={insightTabsData}
        eventContext={Contexts.BOLT}
        currentTabId={selectedTabId}
        exposeOnChange={(newTabId) => setSelectedTabId(newTabId?.toString())}
      />
      {getTabView()}
    </AutoLayout>
  );
};

export default Insights;
