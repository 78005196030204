import React, { FC, useEffect, useState } from 'react';
import AppNavbar from '@paradime-io/pragma-ui-kit/lib/components/AppNavbar';
import CommandBarLauncher from '@paradime-io/pragma-ui-kit/lib/components/CommandBarLauncher';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { IconName, TabId } from '@blueprintjs/core';
import useListenForThemeChange from './hooks/useListenForThemeChange';
import useNavbarActionsListener from './hooks/useNavbarActionsListener';
import useListWorkspaces from '../AccountSettings/Workspaces/hooks/useListWorkspaces';
import useSetWorkspace from '../AccountSettings/Workspaces/hooks/useSetWorkspace';
import KnockFeed from '../Common/Libraries/Knock/KnockFeed';
import KnockToast from '../Common/Libraries/Knock/KnockToast';
import { useGetNavbarProfile } from './hooks/useGetNavbarProfile';
import { companyStore, appStore } from '../../stores';
import CalloutSandboxWorkspace from './CalloutSandboxWorkspace';
import { useGetAppStatus } from './hooks/useGetAppStatus';
import { useGetNavbarTabsData, NavbarAppId } from './hooks/useGetNavbarTabsData';
import * as S from './Navbar.styles';

const getNavMenus = (
  dark: boolean,
) => [
  {
    icon: 'help' as IconName,
    dark,
    eventObject: 'helpNav',
    menu: [],
    onClick: () => {
      if (window.analytics?.track) window.analytics.track('app:helphub_viewed');
      window.CommandBar.openHelpHub();
    },
  },
];

export enum AccountSettingsTab {
  CONNECTIONS = 'connections',
  WORKSPACE = 'current-workspace',
  INTEGRATIONS = 'integrations',
  TEAM = 'team',
  BILLING = 'billing',
  PLANS = 'plans',
  WEBHOOKS = 'webhooks',
  NOTIFICATIONS = 'notifications',
  ENV_VARIABLES = 'env-variables',
  PROFILE = 'profile',
  PRIVACY = 'privacy',
}

export enum PlatformTab {
  WORKSPACES = 'workspaces',
  PLANS = 'plans',
  BILLING = 'billing',
  SETTINGS = 'settings',
  AUDIT_LOGS = 'audit-logs',
  INSIGHTS = 'insights',
}

interface NavBarProps {
  currentApp: string,
  currentAppTabId?: TabId,
}

const NavBar: FC<NavBarProps> = ({ currentApp, currentAppTabId }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const setIsDwhSetupComplete = companyStore((s) => s.setIsDwhSetupComplete);
  const setIsGitSetupComplete = companyStore((s) => s.setIsGitSetupComplete);
  const setIsBasicSetupComplete = companyStore((s) => s.setIsBasicSetupComplete);
  const setCurrentAppTabId = appStore((s) => s.setCurrentAppTabId);

  const history = useHistory();
  const { boot: bootIntercom } = useIntercom();
  const dark = useListenForThemeChange();
  useNavbarActionsListener();
  const navbarProfileMenu = useGetNavbarProfile(isDarkMode);
  const { appStatus } = useGetAppStatus();
  const { navbarTabs, navbarTabPaths } = useGetNavbarTabsData();

  const { joinedWorkspacesList, currentWorkspace } = useListWorkspaces();
  const { setCurrentWorkspace } = useSetWorkspace({
    onCompleted: () => {
      // For users who are trying to escape a partial workspace onboarding
      if (window.location.pathname.includes('create-workspace')) {
        setIsDwhSetupComplete(undefined);
        setIsGitSetupComplete(undefined);
        setIsBasicSetupComplete(undefined);
        history.push('/home');
      }

      window.location.reload();
    },
  });

  useEffect(() => {
    setIsDarkMode(dark);
  }, [dark]);

  useEffect(() => {
    bootIntercom({
      hideDefaultLauncher: true,
    });
  }, []);

  const NavBarRightElements = [<KnockFeed darkMode={isDarkMode} />];

  const hideAppTabs = currentAppTabId
    ? ['home', 'onboarding', 'create-workspace'].includes(currentAppTabId.toString())
    : true;

  return (
    <>
      <div
        id="paradime-navbar"
        style={{
          width: '100vw',
          gridArea: 'n',
          zIndex: 20,
        }}
      >
        <KnockToast />
        <S.NavbarTabs />
        <AppNavbar
          dark={isDarkMode}
          key="navbar"
          currentAppName={hideAppTabs ? 'paradime.io' : undefined}
          navbarTabs={
            (!hideAppTabs) ? {
              tabs: navbarTabs,
              currentTabId: currentAppTabId,
              onChange: (tabId: TabId) => {
                setCurrentAppTabId(tabId);
                history.push(navbarTabPaths[tabId as NavbarAppId]);
              },
            } : undefined
          }
          navbarMenus={getNavMenus(isDarkMode)}
          navbarProfileMenu={navbarProfileMenu}
          widgets={(
            <CommandBarLauncher dark={dark} componentWidth="150px" placeholder="Search..." />
          )}
          widgetsRight={NavBarRightElements}
          workspaceDropdownProps={{
            workspaceList: joinedWorkspacesList.map(
              ({ name, uid }) => ({ label: name, value: uid }),
            ),
            onChange: ({ value }) => {
              setCurrentWorkspace(value);
            },
            initialWorkspaceSelected: {
              label: currentWorkspace?.name, value: currentWorkspace?.uid,
            },
            withActionMenuItem: true,
            onActionMenuItemClick: () => history.push('/settings/workspaces'),
          }}
          appStatus={appStatus}
          homePath="/home"
        />
        {
          (currentApp !== 'Platform') && (
            <CalloutSandboxWorkspace />
          )
        }
      </div>
    </>
  );
};

export default NavBar;
