import React, { FC, useEffect } from 'react';
import Cal, { getCalApi } from '@calcom/embed-react';
import DefaultButton, { DefaultButtonProps } from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';

interface BookDemoProps extends Partial<DefaultButtonProps> {
  cta?: string,
  bookingLink: string,
  embedType: 'inline' | 'popup',
  buttonView?: DefaultButtonProps['view'],
  buttonIcon?: DefaultButtonProps['icon'],
}

const BookDemo:FC<BookDemoProps> = ({
  cta,
  bookingLink,
  embedType,
  buttonView,
  buttonIcon,
  ...buttonProps
}) => {
  useEffect(() => {
    getCalApi({}).then((cal) => {
      cal(
        'ui', {
          theme: 'light',
          styles: {
            branding: {
              brandColor: '#726ae3',
            },
          },
          hideEventTypeDetails: false,
          layout: 'month_view',
        },
      );
    });
  }, []);

  return (
    (embedType === 'inline') ? (
      <Cal
        calLink={bookingLink}
        style={{ width: '100%', height: '100%', overflow: 'scroll' }}
        config={{ layout: 'month_view' }}
      />
    ) : (
      <DefaultButton
        type="standard"
        view={buttonView || 'gradient-secondary'}
        icon={buttonIcon || 'fast-forward'}
        text={cta}
        dense
        eventContext={Contexts.APP}
        eventObject="bookMeetingButton"
        eventAction={Actions.CLICKED}
        data-cal-namespace=""
        data-cal-link={bookingLink}
        data-cal-config='{"layout":"month_view"}'
        style={{ textTransform: 'none' }}
        {...buttonProps}
      />
    )
  );
};

export default BookDemo;
