export type RuntimeStatsType = {
  running: number,
  pass: number,
  warn: number,
  error: number,
  skip: number,
  total: number,
}

export type RuntimeStatsProblemLineIndices = {
  total: number,
  warn: number[],
  error: number[],
  skip: number[],
}

export type LogsParseReturnType = {
  runtimeStats: RuntimeStatsType,
  problemLines?: RuntimeStatsProblemLineIndices,
}

export const parseStatsDbtLogs = (logLines: string[]): LogsParseReturnType => {
  const runtimeStats: RuntimeStatsType = {
    running: 0,
    pass: 0,
    warn: 0,
    error: 0,
    skip: 0,
    total: 0,
  };

  const problemLines: RuntimeStatsProblemLineIndices = {
    total: 0,
    warn: [],
    error: [],
    skip: [],
  };

  const startPattern = /(\d+) of (\d+) START/;
  const endPattern = /(\d+) of (\d+) (OK|ERROR|WARN|SKIP|PASS|FAIL)/;
  const summaryPatternRuns = /Done\. PASS=(\d+) WARN=(\d+) ERROR=(\d+) SKIP=(\d+) TOTAL=(\d+)/;
  const summaryPatternSourceFreshness1 = /.*Finished\s+running\s+(\d+)\s+sources/;
  const summaryPatternSourceFreshness2 = /.*Done.$/;

  const startedTasks = new Set();

  let foundLastLine = false;
  let matchStart: RegExpMatchArray | null;
  let matchEnd: RegExpMatchArray | null;
  let matchSummaryRun: RegExpMatchArray | null;
  let matchSummarySourceFreshness1: RegExpMatchArray | null;
  let matchSummarySourceFreshness2: RegExpMatchArray | null;

  logLines.forEach((line, index) => {
    matchStart = line.match(startPattern);
    matchEnd = line.match(endPattern);
    matchSummaryRun = line.match(summaryPatternRuns);
    matchSummarySourceFreshness1 = line.match(summaryPatternSourceFreshness1);
    matchSummarySourceFreshness2 = line.match(summaryPatternSourceFreshness2);

    if (matchStart) {
      // Get the total number of tasks from the line
      const [, taskId, totalTasks] = matchStart;
      runtimeStats.total = parseInt(totalTasks, 10);
      // Add the task to the started tasks set
      startedTasks.add(taskId);
    }

    if (matchEnd) {
      // Get the task id and status from the line
      const [, taskId, , status] = matchEnd;
      switch (status) {
        case 'OK':
        case 'PASS':
          runtimeStats.pass += 1;
          break;
        case 'FAIL':
        case 'ERROR':
          runtimeStats.error += 1;
          problemLines.error.push(index);
          problemLines.total += 1;
          break;
        case 'WARN':
          runtimeStats.warn += 1;
          problemLines.warn.push(index);
          problemLines.total += 1;
          break;
        case 'SKIP':
          runtimeStats.skip += 1;
          problemLines.skip.push(index);
          problemLines.total += 1;
          break;
        default:
          break;
      }
      startedTasks.delete(taskId);
    }

    if (matchSummaryRun) {
      // Get the summary from the last line, available after the run is complete.
      const [, pass, warn, error, skip, total] = matchSummaryRun.map(Number);
      Object.assign(runtimeStats, {
        pass, warn, error, skip, total, running: 0,
      });
      foundLastLine = true;
    }

    if (matchSummarySourceFreshness1) {
      // Get the summary from the last line, available after the run is complete.
      const [, total] = matchSummarySourceFreshness1.map(Number);
      Object.assign(runtimeStats, {
        total, running: 0,
      });
      foundLastLine = true;
    }

    if (matchSummarySourceFreshness2) {
      // calculate the total
      const total = runtimeStats.pass + runtimeStats.error + runtimeStats.warn + runtimeStats.skip;
      Object.assign(runtimeStats, {
        total, running: 0,
      });
      foundLastLine = true;
    }
  });

  if (!foundLastLine) {
    runtimeStats.running = startedTasks.size;
    return {
      runtimeStats,
    };
  }

  return {
    runtimeStats,
    problemLines,
  };
};
