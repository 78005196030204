import { IconName } from '@blueprintjs/icons';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { Position, Node, XYPosition } from 'reactflow';

export interface ScheduleNodeProps {
  id: string,
  name: string | JSX.Element,
  color: colorType,
  position: XYPosition,
  icon: IconName,
  iconColor: string,
  onClick: () => void,
}

const useGetNode = () => {
  const getNode = ({
    id, name, color, position, icon, iconColor, onClick,
  }: ScheduleNodeProps): Node => (
    {
      id,
      type: 'ScheduleNode',
      position,
      data: {
        nodeName: name,
        nodeColor: color,
        icon,
        iconColor,
        x: 25,
        y: 55,
        focussed: false,
        selected: false,
        onClick,
        targetHandlePosition: Position.Left,
        sourceHandlePosition: Position.Right,
      },
    }
  );

  return {
    getNode,
  };
};

export { useGetNode };
