import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { PlatformTab } from '../../NavBar';
import { RequestType, useUpgradeRequest } from '../../../utilis/useUpgradeRequest';

interface PaywallProps {
  isAdmin: boolean;
  upgradePlanName: string,
  header: string,
  bodyText: string | JSX.Element,
  buttonClickEventContext: Contexts,
  featureText: string,
  docsUrl: string,
  upgradeImage: string,
  needsSupportToEnable?: boolean,
}

const Upgrade:FC<PaywallProps> = ({
  isAdmin,
  upgradePlanName,
  header,
  bodyText,
  buttonClickEventContext,
  featureText,
  docsUrl,
  upgradeImage,
  needsSupportToEnable,
}) => {
  const history = useHistory();
  const { upgradeRequest } = useUpgradeRequest();

  const getButtonText = () => {
    if (needsSupportToEnable && isAdmin) return 'Contact us';
    if (isAdmin) return 'Upgrade';
    return 'Request Upgrade*';
  };

  return (
    <Card
      tabIndex={-1}
      height="403px"
      padding="normal"
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="space-between"
        style={{ gridAutoColumns: 'unset' }}
      >
        <AutoLayout
          direction="vertical"
          verticalGap="expanded"
          padding="none"
          distribution="packed"
        >
          <Chips
            type="dense"
            icon="lock"
            tag={upgradePlanName}
            color="warning_alt"
            round={false}
          />

          <AutoLayout
            direction="vertical"
            verticalGap="dense"
            padding="none"
            distribution="packed"
          >
            <Typography tagName="span" type="h5" color="default">
              {header}
            </Typography>

            <Typography tagName="span" type="body-small" color="default">
              {bodyText}
            </Typography>
          </AutoLayout>

          <AutoLayout
            direction="vertical"
            verticalGap="dense"
            padding="none"
            distribution="packed"
          >
            <AutoLayout
              direction="horizontal"
              padding="none"
              horizontalGap="dense"
              distribution="packed"
            >
              <DefaultButton
                dense
                text={getButtonText()}
                eventContext={buttonClickEventContext}
                eventObject={isAdmin ? 'viewPlans' : 'requestAccess'}
                eventProperties={{
                  feature: featureText,
                  needsSupportToEnable,
                }}
                eventAction={Actions.CLICKED}
                view="gradient-primary"
                icon="rocket-slant"
                onClick={() => {
                  if (isAdmin) {
                    history.push(`/settings/${PlatformTab.PLANS}`);
                  } else {
                    upgradeRequest({
                      requestType: RequestType.FEATURE_REQUEST, feature: featureText,
                    });
                  }
                }}
              />
              <DefaultButton
                dense
                view="outlined"
                icon="manual"
                text="Learn more"
                eventContext={buttonClickEventContext}
                eventObject="readDocs"
                eventAction={Actions.CLICKED}
                eventProperties={{
                  feature: featureText,
                }}
                onClick={() => {
                  window.open(docsUrl, '_blank');
                }}
              />
            </AutoLayout>
            {!isAdmin && (
              <Typography
                tagName="span"
                type="caption"
                color="default"
                colorStep="50"
              >
                * We will send your request to your account admin(s).
              </Typography>
            )}
          </AutoLayout>
        </AutoLayout>

        <img
          src={upgradeImage}
          alt={`${featureText} upgrade`}
          height="333px"
          width="500px"
          style={{
            maxWidth: '500px',
          }}
        />
      </AutoLayout>
    </Card>
  );
};

export default Upgrade;
