/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Typography } from '@paradime-io/pragma-ui-kit';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { triggerAlert, useGetJwtToken } from '../../../utilis';
import { userAuthStore, companyStore } from '../../../stores';
import {
  useGetAllClientIdsQuery,
  useSnowflakeOauthTokenExpiryLazyQuery,
  GetAllClientIdsQuery,
} from '../../../client/generated/service';
import { useHandleClosableWindow } from '../../hooks/useHandleClosableWindow';

type configuration = NonNullable<GetAllClientIdsQuery['getOrganisationDbConfigs']['configurations'][number]>

interface listOfConnectionsType {
  configuration: configuration,
  isChecked: boolean,
}
interface updateConfigurationExpiryStatusProps {
  credentialId: string,
  newCheckStatus: boolean,
}

const useListSnowflakeOAuthConnections = () => {
  const [listOfConnections, setListOfConnections] = useState<listOfConnectionsType[]>([]);

  const { data: clientIdsData } = useGetAllClientIdsQuery();

  useEffect(() => {
    if (clientIdsData?.getDwhProductionConfigs.ok && clientIdsData.getOrganisationDbConfigs.ok) {
      const fullList = [
        ...clientIdsData.getDwhProductionConfigs.configurations,
        ...clientIdsData.getOrganisationDbConfigs.configurations,
      ];

      const oAuthConnections = fullList.filter(({ configuration }) => (
        Boolean(configuration?.snowflakeConfig?.clientId)
      ));

      setListOfConnections(
        oAuthConnections.map((configuration) => ({ configuration, isChecked: false })),
      );
    }
  }, [clientIdsData]);

  const updateConfigurationCheckStatus = ({
    credentialId,
    newCheckStatus,
  }: updateConfigurationExpiryStatusProps) => {
    // Iterate over the list of connections until finding the specified one
    // Then update its expiryState to the value passed in
    setListOfConnections((currentListOfConnections) => (
      currentListOfConnections.map(({ configuration, isChecked }) => {
        if (configuration.credentialId === credentialId) {
          return { configuration, isChecked: newCheckStatus };
        }
        return { configuration, isChecked };
      })
    ));
  };

  return { listOfConnections, updateConfigurationCheckStatus };
};

const useHandleSnowflakeOAuth = () => {
  const [currentExpiredConnection, setCurrentExpiredConnection] = useState<configuration>();
  const [checkAuthState, { data: authStateData }] = useSnowflakeOauthTokenExpiryLazyQuery();
  const { listOfConnections, updateConfigurationCheckStatus } = useListSnowflakeOAuthConnections();

  const { getToken } = useGetJwtToken();
  const companyToken = userAuthStore((state) => state.companyToken);
  const snowflakeCallbackUrl = companyStore((state) => state.snowflakeCallbackUrl);

  const history = useHistory();
  const { pathname } = window.location;

  const { openWindow, handleWindowClose } = useHandleClosableWindow();

  const encodeUserIdFromJwtToken = async (jwtToken: string) => {
    const { sub } = JSON.parse(jwtToken);
    const encoder = new TextEncoder();
    const data = encoder.encode(sub);
    const hashArrayBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashArrayBuffer));
    const hash = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
    return hash;
  };

  // Returns the first connection which has not been checked yet
  const getFirstCheckableConnection = () => (
    listOfConnections.filter(({ isChecked }) => !isChecked)?.[0]
  );

  // Get the first unchecked connection and ask the BE to check if it has expired
  const fetchAndCheckFirstConnection = () => {
    if (currentExpiredConnection) {
      launchAuthExpiredAlert(currentExpiredConnection);
    } else {
      const firstCheckableConnection = getFirstCheckableConnection();
      if (firstCheckableConnection) {
        // Ask the BE if this connection has expired
        checkAuthState({
          variables: {
            credentialId: firstCheckableConnection.configuration.credentialId,
          },
        });
      }
    }
  };

  useEffect(() => {
    fetchAndCheckFirstConnection();
  }, [listOfConnections]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timeout: NodeJS.Timeout;

    if (authStateData?.snowflakeOauthTokenExpiry?.ok) {
      const { hasExpired, expiresEpoch } = authStateData.snowflakeOauthTokenExpiry;
      const firstCheckableConnection = getFirstCheckableConnection();

      if (hasExpired) {
        // Trigger the pop-up
        setCurrentExpiredConnection(firstCheckableConnection.configuration);
        launchAuthExpiredAlert(firstCheckableConnection.configuration);
      } else {
        // Wait for the amount of time that the expiresEpoch specifies
        const { configuration: { credentialId } } = firstCheckableConnection;
        const timeoutDelay = DateTime.fromSeconds(expiresEpoch, { zone: 'utc' }).diffNow().toMillis();
        const oneWeekInMilliseconds = 604800 * 1000;

        // Turns out setTimeout has a maximum timeout value (~24 days)
        // If you exceed that value, the function just executes immediately
        // So, don't create the timeout if the expiry time is too far away
        if (timeoutDelay < oneWeekInMilliseconds) {
          timeout = setTimeout(() => {
            // Ask the BE if this connection has expired
            checkAuthState({
              variables: {
                credentialId,
              },
            });
          }, timeoutDelay);
        }
      }
    }

    return () => { if (timeout) clearTimeout(timeout); };
  }, [authStateData]);

  const excludedUrls = [
    '/onboarding',
    '/create-workspace',
  ];

  const launchAuthExpiredAlert = (connection: configuration) => {
    if (excludedUrls.some((url) => pathname.includes(url))) {
      return;
    }

    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = 700;
    const height = 900;

    const systemZoom = window.innerWidth / window.screen.availWidth;
    const left = (window.innerWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (window.innerHeight - height) / 2 / systemZoom + dualScreenTop;

    let { location: { host } } = window;
    if (host.includes('localhost')) host = 'app.dev.paradime.io';

    getToken()
      .then((jwtToken) => {
        const jwtChunk = jwtToken.split('.')[1];
        // The jwt token was encoded using modified b64 for url's
        // '+' was replaced with '-' and '/' was repalced with '_'
        // atob() expects a standard b64 string, so undo these changes
        // https://en.wikipedia.org/wiki/Base64#URL_applications
        const jwtWithoutUnderscores = jwtChunk.replace(/_/g, '/');
        const jwtWithoutHyphens = jwtWithoutUnderscores.replace(/-/g, '+');
        const decodedJwtToken = atob(jwtWithoutHyphens);

        const { credentialId } = connection;
        if (!connection.configuration?.snowflakeConfig) return;
        const { clientId } = connection.configuration.snowflakeConfig;

        const accountName = connection.configuration?.snowflakeConfig?.account.replace('.privatelink', '');
        const baseUrl = `https://${accountName}.snowflakecomputing.com/oauth/authorize`;
        const encodedClientId = encodeURIComponent(clientId || '');

        encodeUserIdFromJwtToken(decodedJwtToken)
          .then((userId) => {
            const role = connection?.configuration?.snowflakeConfig?.role;
            const state = {
              'credential-id': credentialId,
              'user-id': userId,
              'company-token': companyToken,
              role,
            };
            if (Object.keys(state).length !== 4) {
              console.error(`Pop-up. One of credential id, user id, company token or role is missing: ${JSON.stringify(state)}`); // eslint-disable-line no-console
            }
            const encodedState = btoa(JSON.stringify(state));
            const scope = `refresh_token session:role:${role}`;

            const oAuthUrl = `${baseUrl}?client_id=${encodedClientId}&display=popup&redirect_uri=${snowflakeCallbackUrl}&response_type=code&scope=${scope}&state=${encodedState}=`;

            triggerAlert({
              title: 'Snowflake token expired!',
              icon: 'snowflake',
              color: 'default', // @ts-ignore - message expects a string not an Element
              message: (
                <div>
                  <Typography type="body-small">
                    Your Snowflake refresh token is expired.
                  </Typography>
                  <br />
                  <Typography type="body-small">
                    You will need to re-authorize with Snowflake
                    to continue developing dbt using Paradime.
                  </Typography>
                </div>
              ),
              rightButtonText: 'Connect Snowflake',
              rightButtonEventData: {
                eventAction: Actions.CLICKED,
                eventContext: Contexts.EDITOR,
                eventObject: 'reAuthenticatedSnowflake',
              },
              onRightButtonClick: () => {
                openWindow({
                  url: oAuthUrl,
                  target: '_blank',
                  options: `width=${width} height=${height} left=${left} top=${top}`,
                  launchedFrom: 'useHandleSnowflakeOAuth',
                });
              },
              view: 'raised',
            });
          });
      });
  };

  useEffect(() => {
    fetchAndCheckFirstConnection();

    // Once the connection has been re-authenticated, mark it as "checked"
    const onConnectionReAuth = () => {
      if (currentExpiredConnection) {
        const currConnectionId = currentExpiredConnection?.credentialId;
        setCurrentExpiredConnection(undefined);
        updateConfigurationCheckStatus({
          credentialId: currConnectionId,
          newCheckStatus: true,
        });
      }
      setCurrentExpiredConnection(undefined);
    };
    handleWindowClose(onConnectionReAuth, 'useHandleSnowflakeOAuth');
  }, [pathname]);

  useEffect(() => {
    const handleSnowAuthSuccess = (event: MessageEvent) => {
      const { type } = event.data;

      // Only show success/error alerts if on the editor
      if (pathname.includes('editor')) {
        if (type === 'snowflake-auth-success') {
          triggerAlert({
            title: 'Snowflake OAuth',
            icon: 'snowflake',
            color: 'default',
            message: 'Your are connected to your data warehouse.',
            rightButtonText: 'Close',
            rightButtonEventData: {
              eventAction: Actions.CLICKED,
              eventContext: Contexts.EDITOR,
              eventObject: 'authenticationSnowflakeSucceeded',
            },
            view: 'raised',
          });
        }
        if (type === 'snowflake-auth-failed') {
          triggerAlert({
            title: 'Snowflake Oauth Error',
            icon: 'warning-sign',
            color: 'danger', // @ts-ignore
            message: (
              <div>
                <Typography type="body-small">
                  Paradime was not able to connect to your Snowflake database.
                </Typography>
                <br />
                <Typography type="body-small">
                  Check your database credentials and try again.
                </Typography>
              </div>
            ),
            rightButtonText: 'Open Connection Settings',
            rightButtonEventData: {
              eventAction: Actions.CLICKED,
              eventContext: Contexts.EDITOR,
              eventObject: 'authenticationSnowflakeFailed',
            },
            view: 'raised',
            onRightButtonClick: () => history.push('/settings/connections'),
          });
        }
      }
    };

    window.addEventListener('message', handleSnowAuthSuccess);

    return () => window.removeEventListener('message', handleSnowAuthSuccess);
  }, []);
};

export default useHandleSnowflakeOAuth;
