import React, { FC, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { companyStore } from '../../../stores';
import TrialExpired from './TrialExpired';
import NoSubscription from './NoSubscription';

const enabledRoutes = [
  '/settings/plans',
  '/settings/billing',
];

const AccountLockedDialog: FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const companyPlan = companyStore((s) => s.companyPlan);
  const accountIsLocked = companyPlan?.trialHasExpired || companyPlan?.userHasCancelled || false;

  const { pathname } = window.location;

  useEffect(() => {
    const isEnabledRoute = enabledRoutes.some((route) => route === pathname);

    if (isEnabledRoute) {
      // On enabled routes, hide the dialog
      setIsVisible(false);
      return;
    }

    setIsVisible(accountIsLocked);
  }, [pathname, accountIsLocked]);

  return (
    <Dialog
      visible={isVisible}
      onHide={() => {}}
      closable={false}
      closeOnEscape={false}
      dismissableMask={false}
      draggable={false}
      resizable={false}
    >
      {companyPlan?.trialHasExpired
        ? <TrialExpired />
        : <NoSubscription />}
    </Dialog>
  );
};

export default AccountLockedDialog;
