export enum EnvVarHelp {
  PRODUCTION_ENV_VARIABLES = 162750,
  USER_LEVEL_ENV_VARIABLES = 158378,
}

export enum AccountSettingsHelp {
  WORKSPACE_PROJECT_SETTINGS = 162742,
  WORKSPACE_ENV_VAR = 162750,
  WORKSPACE_REPOSITORY = 162743,
  WORKSPACE_API_KEYS = 185104,
  WORKSPACE_WEBHOOKS = 123,
  WORKSPACE_TEAMS = 162744,
  WORKSPACE_DEV_CONNECTIONS = 162709,
  WORKSPACE_PROD_CONNECTIONS = 162715,
  WORKSPACE_META_CONNECTIONS = 162721,
  WORKSPACE_COST_CONNECTIONS = 378059,
}

export enum LineageHelp {
  SEARCH_LINEAGE = 171601,
  COMPARE_LINEAGE = 171606,
  CHANGE_NODE_DEPTH = 171633,
}

export enum CatalogHelp {
  CATALOG_SEARCH_DISCOVERY = 171607,
  CATALOG_FILTERING = 123,
  CATALOG_DATA_ASSETS = 162753,
  CATALOG_DBT_ASSETS = 171610,
  CATALOG_LOOKER_ASSETS = 171611,
  CATALOG_TABLEAU_ASSETS = 171612,
  CATALOG_FIVETRAN_ASSETS = 171613,
}

export enum BoltHelp {
  BOLT_WRITING_SCHEDULES = 162690,
  BOLT_HELP_UI = 162696,
  THOUGHTSPOT = 363848,
  COMMANDS = 714541,
}

export enum RadarHelp {
  SCHEDULES = 458040,
  MODELS = 458040,
  SOURCES = 458040,
  TESTS = 458040,
  WORK = 458040,
  COST = 458040,
  LOOKER = 458040,
  OPTIMISATIONS = 618344,
}

export enum IntegrationsHelp {
  SLACK = 162735,
  LOOKER = 162733,
  TABLEAU = 162734,
  POWER_BI = 189568,
  FIVETRAN = 162730,
  HIGHTOUCH = 162732,
  GITHUB = 162706,
  DBT_CLOUD = 162729,
  THOUGHTSPOT = 420756,
  SIGMA = 575914,
  TEAMS = 671276,
}

export enum GitHelp {
  AZURE_DEV_OPS = 162704,
  BIT_BUCKET = 162705,
  GITHUB = 162706,
  GITLAB = 162707,
}

export enum DevWarehouseHelp {
  BIG_QUERY = 162710,
  FIREBOLT = 162712,
  DATABRICKS = 185097,
  REDSHIFT = 162713,
  SNOWFLAKE = 162714,
  DUCK_DB = 313563,
  MOTHERDUCK = 313563,
  CUSTOM_CONFIG = 699881,
}

export enum ProdWarehouseHelp {
  BIG_QUERY = 162716,
  FIREBOLT = 162718,
  DATABRICKS = 162717,
  REDSHIFT = 162719,
  SNOWFLAKE = 162720,
  DUCK_DB = 313564,
  MOTHERDUCK = 313564,
}

export enum MetadataWarehouseHelp {
  BIG_QUERY = 162722,
  FIREBOLT = 162724,
  REDSHIFT = 162725,
  SNOWFLAKE = 162726,
}

export enum PlatformSettingsHelp {
  AUDIT_LOGS = 420757,
}
