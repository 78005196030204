import React, { FC /* , useEffect, useState */ } from 'react';
// import { useScheduleCommandLogsSubscription } from '../../../..
// /client/generated/service-dataEndpoint';
import Command, { CommandProps } from '../CommandLogs';

const CancelledCommand: FC<CommandProps> = (props) => {
  const {
    meta,
  } = props;

  // const [newOutput, setNewOutput] = useState<string>();

  // const { data: subscriptionData } = useScheduleCommandLogsSubscription({
  //   variables: {
  //     commandID: meta.id,
  //   },
  // });

  // useEffect(() => {
  //   if (subscriptionData?.scheduleCommandLogs.stdout) {
  //     setNewOutput(subscriptionData.scheduleCommandLogs.stdout);
  //   }
  // }, [subscriptionData]);

  return (
    <Command
      {...props}
      // output={newOutput}
      commandId={meta.id}
    />
  );
};

export default CancelledCommand;
