import { TabData } from '@paradime-io/pragma-ui-kit/lib/components/TabsDefault';

export enum NavbarAppId {
  EDITOR = 'editor',
  BOLT = 'bolt',
  RADAR = 'radar',
  CATALOG = 'catalog',
  LINEAGE = 'lineage',
  SETTINGS = 'settings',
}

export const useGetNavbarTabsData = () => {
  const navbarTabsData: TabData[] = [
    {
      id: NavbarAppId.EDITOR,
      title: 'Editor',
      visible: true,
    },
    {
      id: NavbarAppId.BOLT,
      title: 'Bolt',
      visible: true,
    },
    {
      id: NavbarAppId.RADAR,
      title: 'Radar',
      visible: true,
    },
    {
      id: NavbarAppId.CATALOG,
      title: 'Catalog',
      visible: true,
    },
    {
      id: NavbarAppId.LINEAGE,
      title: 'Lineage',
      visible: true,
    },
    {
      id: NavbarAppId.SETTINGS,
      title: 'Settings',
      visible: true,
    },
  ];

  const navbarTabPaths = {
    [NavbarAppId.EDITOR]: '/editor',
    [NavbarAppId.BOLT]: '/bolt',
    [NavbarAppId.RADAR]: '/radar/schedules',
    [NavbarAppId.CATALOG]: '/catalog/search',
    [NavbarAppId.LINEAGE]: '/lineage/home',
    [NavbarAppId.SETTINGS]: '/settings/connections',
  };

  return {
    navbarTabs: navbarTabsData,
    navbarTabPaths,
  };
};
