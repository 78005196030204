import { useEffect, useState } from 'react';
import {
  GqlScheduleTrigger,
  useGetScheduleNameTriggerDependenciesLazyQuery,
} from '../../../client/generated/service';

export type ScheduleTriggerType = Omit<GqlScheduleTrigger, '__typename'>;

const useGetUpstreamDownstreamTriggers = (scheduleName?: string) => {
  const [upstreamTrigger, setUpstreamTrigger] = useState<ScheduleTriggerType>();
  const [downstreamTriggers, setDownstreamTriggers] = useState<ScheduleTriggerType[]>();
  const [getScheduleNameTriggerDependencies] = useGetScheduleNameTriggerDependenciesLazyQuery();

  useEffect(() => {
    if (scheduleName) {
      getScheduleNameTriggerDependencies({
        variables: { scheduleName },
        onCompleted: (data) => {
          if (data.getScheduleNameTriggerDependencies?.scheduleTriggerParent) {
            setUpstreamTrigger(data.getScheduleNameTriggerDependencies.scheduleTriggerParent);
          }

          if (data.getScheduleNameTriggerDependencies?.scheduleTriggerChildren) {
            setDownstreamTriggers(data.getScheduleNameTriggerDependencies.scheduleTriggerChildren);
          }
        },
      });
    }
  }, [scheduleName]);

  return {
    upstreamTrigger,
    downstreamTriggers,
  };
};

export { useGetUpstreamDownstreamTriggers };
