import React from 'react';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { IconName } from '@blueprintjs/icons';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { ScheduleType } from '.';
import { ScheduleName } from './Scheduler/ScheduleList';
import { ScheduleTriggerType } from './hooks/useGetUpstreamDownstreamTriggers';

import dbtLogo from '../AccountSettings/Integrations/images/dbt.svg';
import powerBiLogo from '../AccountSettings/Integrations/images/powerbi.svg';
import tableauLogo from '../AccountSettings/Integrations/images/tableau.svg';
import elementaryLogo from '../AccountSettings/Integrations/images/elementary.svg';
import pythonLogo from '../AccountSettings/Integrations/images/python.svg';
import lightdashLogo from '../AccountSettings/Integrations/images/lightdash.svg';
import montecarloLogo from '../AccountSettings/Integrations/images/montecarlo.svg';
import paradimeLogo from '../AccountSettings/Integrations/images/paradime.svg';
import customCommandLogo from '../AccountSettings/Integrations/images/customCommand.svg';

export const getScheduleType = (schedule?: ScheduleType) => {
  if (schedule?.turboCi?.enabled) return 'turbo_ci';
  if (schedule?.deferredSchedule?.enabled) return 'deferred';
  return 'standard';
};

export const getScheduleState = (schedule: ScheduleName) => {
  let textAndColor = {
    color: schedule.state.colorType as colorType,
    text: schedule.state.text,
  };
  if (schedule.state.text === 'Finished') {
    textAndColor = {
      color: schedule.lastRunState.colorType as colorType,
      text: schedule.lastRunState.text,
    };
  }
  return textAndColor;
};

export const isValidCronDayOfTheWeek = (cronFields: any): boolean => {
  // get the last day of the week
  const maxDay = Math.max(...cronFields.dayOfWeek);

  // if we have * i.e. all days, its ok
  if (cronFields.dayOfWeek.length === 8) {
    return true;
  }

  // if the last day of the week is 7, its invalid
  if (maxDay >= 7) {
    return false;
  }

  return true;
};

export enum BoltCommandTypes {
  DBT = 'dbt',
  PYTHON = 'python',
  ELEMENTARY = 'edr',
  LIGHTDASH = 'lightdash',
  POWER_BI = 'power-bi',
  TABLEAU = 'tableau',
  MONTECARLO = 'montecarlo',
  BOLT_SCHEDULE = 'paradime bolt schedule',
  CUSTOM_COMMAND = 'custom-command',
}

export const getCommandType = (command: string) => {
  if (command.includes('power-bi-refresh')) return BoltCommandTypes.POWER_BI;
  if (command.includes('tableau-refresh')) return BoltCommandTypes.TABLEAU;
  if (command.includes('bolt schedule')) return BoltCommandTypes.BOLT_SCHEDULE;

  const commandPrefix = command.split(' ')[0];

  switch (commandPrefix) {
    case 'dbt':
      return BoltCommandTypes.DBT;
    case 'poetry':
    case 'python':
      return BoltCommandTypes.PYTHON;
    case 'edr':
      return BoltCommandTypes.ELEMENTARY;
    case 'lightdash':
      return BoltCommandTypes.LIGHTDASH;
    case 'montecarlo':
      return BoltCommandTypes.MONTECARLO;
    default:
      return BoltCommandTypes.CUSTOM_COMMAND;
  }
};

export const getCommandImage = (commandType: string) => {
  switch (commandType) {
    case BoltCommandTypes.DBT:
      return dbtLogo;
    case BoltCommandTypes.PYTHON:
      return pythonLogo;
    case BoltCommandTypes.ELEMENTARY:
      return elementaryLogo;
    case BoltCommandTypes.LIGHTDASH:
      return lightdashLogo;
    case BoltCommandTypes.POWER_BI:
      return powerBiLogo;
    case BoltCommandTypes.TABLEAU:
      return tableauLogo;
    case BoltCommandTypes.MONTECARLO:
      return montecarloLogo;
    case BoltCommandTypes.BOLT_SCHEDULE:
      return paradimeLogo;
    case BoltCommandTypes.CUSTOM_COMMAND:
      return customCommandLogo;
    default:
      return '';
  }
};
export const getScheduleNodeIcon = (color?: colorType): IconName => {
  switch (color) {
    case 'success':
      return 'tick-circle';
    case 'danger':
      return 'error';
    default:
      return 'info-sign';
  }
};

export const getCommandNodeName = (commandString: string) => (
  <>
    <Typography type="node-bold" tagName="span">
      Command:
    </Typography>
    <Typography type="caption" tagName="span">
      {commandString}
    </Typography>
  </>
);

export const getScheduleNodeName = (schedule: ScheduleTriggerType) => (
  <>
    <Typography type="node-bold" tagName="span">
      Schedule:
    </Typography>
    <Typography type="caption" tagName="span">
      {schedule.scheduleName}
    </Typography>
    <Typography type="caption-small" tagName="span">
      {`Workspace: ${schedule.workspaceName}`}
    </Typography>
  </>
);
