import { useContext } from 'react';
import { RunningContext } from '../Scheduler/Run';
import { useBoltArtifactByPathLazyQuery } from '../../../client/generated/service';
import { boltStore } from '../../../stores';

const useViewArtifact = () => {
  const { setShowArtifactSql } = useContext(RunningContext);
  const setSqlResourceId = boltStore((s) => s.setSqlResourceId);
  const setSqlResourcePath = boltStore((s) => s.setSqlResourcePath);
  const [
    queryBoltArtifactByPath,
    { loading: loadingArtifactDetails },
  ] = useBoltArtifactByPathLazyQuery();

  const viewArtifactSql = (id: number, filePath: string) => {
    setSqlResourceId(id);
    setSqlResourcePath(filePath);
    setShowArtifactSql(true);
  };

  const viewArtifactSqlFromPath = (cid: number, filePath: string) => {
    queryBoltArtifactByPath({
      variables: {
        commandId: cid,
        path: filePath,
      },
      onCompleted: (data) => {
        if (data.boltArtifactByPath?.artifact?.id) {
          viewArtifactSql(data.boltArtifactByPath.artifact?.id, filePath);
        }
      },
    });
  };

  return {
    viewArtifactSql,
    viewArtifactSqlFromPath,
    loadingArtifactDetails,
  };
};

export { useViewArtifact };
