import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import { companyStore, userAuthStore } from '../../stores';
import {
  SynqOnboardingRoutePrefix,
  SYNQ_ONBOARDING_PAGE,
} from '../Onboarding/SynqOnboarding';
import { useOnboardUser } from '../../utilis/useOnboardUser';

const AuthSuccess: FC = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { location: { href } } = window;
  const url = new URL(href);
  const isSignup = url.searchParams.get('is_signup') === 'true';
  const origin = url.searchParams.get('origin');
  const isFromTheExtension = origin === 'chrome-extension';
  const targetIsTheExtension = url.searchParams.get('target') === 'chrome-extension';

  const isFirstAdminFromStore = companyStore((state) => state.isFirstAdmin);
  const isExtensionOnlyCompany = companyStore((state) => state.isExtensionOnlyCompany);
  const { goBackTo } = userAuthStore.getState();

  const { canOnboardViaInviteToken } = useOnboardUser();

  const signupParams = url.searchParams.toString();

  const getRedirectUrl = async () => {
    if (isFromTheExtension) {
      return '/extension-login-success';
    }

    if (targetIsTheExtension || isExtensionOnlyCompany) {
      return `/${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.JOIN_EXISTING}${search}`;
    }

    // If the user has an invalid URL param, or missing URL param,
    // we assume they wanted the main app
    if (!targetIsTheExtension && isSignup) {
      if (!canOnboardViaInviteToken) { // TODO: check interaction with auto-join
        return '/settings/workspaces';
      }

      return undefined;
    }

    if (goBackTo) return goBackTo;

    return signupParams ? `/home?${signupParams}` : '/home';
  };

  useEffect(() => {
    getRedirectUrl()
      .then((redirectUrl) => {
        if (redirectUrl) history.push(redirectUrl);
      });
  }, [isFirstAdminFromStore]);

  return <Loader />;
};

export default AuthSuccess;
