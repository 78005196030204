import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { userAuthStore } from '../../../stores';
import { userHasPlatformPlansUpdateAccess } from '../../../utilis/PermissionsService';
import { RequestType, useUpgradeRequest } from '../../../utilis/useUpgradeRequest';

const ButtonsRow: FC = () => {
  const [upgradeWasRequested, setUpgradeWasRequested] = useState(false);

  const history = useHistory();

  const { upgradeRequest, upgradeRequestInProgress: loading } = useUpgradeRequest();

  const { accessLevel } = userAuthStore((state) => state.currentUser);
  const hasUpgradePermissions = userHasPlatformPlansUpdateAccess(accessLevel);

  const getButtonText = () => {
    if (hasUpgradePermissions) return 'Upgrade now';
    if (upgradeWasRequested) return 'Upgrade Requested';
    return 'Request upgrade*';
  };

  return (
    <AutoLayout
      direction="vertical"
      verticalGap="dense"
      padding="none"
      distribution="packed"
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        horizontalGap="very-dense"
        distribution="packed"
        alignment="center"
      >
        <DefaultButton
          type="standard"
          view="gradient-secondary"
          text="Speak with us"
          dense
          onClick={() => window.open('https://cal.com/kaustav/paradime-support', '_blank')}
          eventContext={Contexts.APP}
          eventObject="accountLocked"
          eventAction={Actions.CLICKED}
          eventProperties={{ action: 'contact-us' }}
        />
        <DefaultButton
          type="standard"
          view="gradient-primary"
          text={getButtonText()}
          dense
          loading={loading}
          icon={upgradeWasRequested ? 'tick-circle' : undefined}
          disabled={upgradeWasRequested}
          onClick={() => {
            if (hasUpgradePermissions) {
              history.push('/settings/plans');
            } else {
              upgradeRequest({ requestType: RequestType.ACCOUNT_UNLOCK_REQUEST, feature: 'account locked' }).then(() => setUpgradeWasRequested(true));
            }
          }}
          eventContext={Contexts.APP}
          eventObject="accountLocked"
          eventAction={Actions.CLICKED}
          eventProperties={{ action: hasUpgradePermissions ? 'view-plans' : 'request-access' }}
        />
      </AutoLayout>
      {!hasUpgradePermissions && (
        <Typography
          tagName="span"
          type="caption"
          color="default"
          colorStep="50"
          style={{ textAlign: 'center' }}
        >
          * We will send your request to your account admin(s).
        </Typography>
      )}
    </AutoLayout>
  );
};

export default ButtonsRow;
