import React, { Dispatch, FC, SetStateAction } from 'react';
import { Icon } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { Menu } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import MenuDivider from '@paradime-io/pragma-ui-kit/lib/components/MenuDivider';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Checkbox from '@paradime-io/pragma-ui-kit/lib/components/CheckBox';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { handleFilterChange } from '../../Lineage/utils';
import { lineageFilterNodeReturnType } from '../../../integrations/types';
import { AccountSettingsTab } from '../../NavBar';

export interface NodeFilterProps {
  lookerStatus: boolean,
  nodeTypeChecks: lineageFilterNodeReturnType[],
  setNodeTypeChecks: Dispatch<SetStateAction<lineageFilterNodeReturnType[]>>,
  setNodesFilter: Dispatch<SetStateAction<string[]>>,
}

export interface handleClickProps {
  label: string,
}

const NodeFilter: FC<NodeFilterProps> = ({
  nodeTypeChecks,
  setNodeTypeChecks,
  setNodesFilter,
}) => {
  const [type, view, color] = ['dense', 'raised', 'default'] as any[];

  const history = useHistory();

  const handleClick = ({ label }: handleClickProps) => {
    setNodeTypeChecks((current) => (
      current.map((itemWithTitle) => ({
        ...itemWithTitle,
        items: itemWithTitle.items.map((item) => {
          if (item.label[0] === label) {
            return { ...item, checked: !item.checked };
          }
          return item;
        }),
      }))
    ));
  };

  const submitText = () => {
    let totalNodesActive = 0;
    nodeTypeChecks.forEach((itemWithTitle) => {
      totalNodesActive += itemWithTitle.items.filter((item) => item.checked).length;
    });
    const typePluralized = totalNodesActive === 1 ? 'type' : 'types';
    return `select ${totalNodesActive} node ${typePluralized}`;
  };

  const nodeTypeChecksWithoutTitles = () => {
    const listWithoutTitles: lineageFilterNodeReturnType['items'] = [];
    nodeTypeChecks.forEach((itemWithTitle) => {
      listWithoutTitles.push(...itemWithTitle.items);
    });
    return listWithoutTitles;
  };

  return (
    <div style={{ maxWidth: 300 }} data-testid="nodeFilter">
      <Menu
        type={type}
        view={view}
        color={color}
        style={{ maxHeight: 600, overflowY: 'auto' }}
      >
        {nodeTypeChecks.map((itemWithTitle) => (
          <>
            <MenuDivider
              color={color}
              title={itemWithTitle.title}
              type="dense"
              view="filled"
            />
            {itemWithTitle.items.map((item) => (
              <Checkbox
                data-testid="filterCheckbox"
                key={`${item.label[1]}-checkbox`}
                label={item.label[1]}
                view="smooth"
                fill
                type="dense"
                style={{
                  color: 'var(--grey100)',
                  margin: '2px 16px',
                }}
                onChange={() => handleClick({ label: item.label[0] })}
                checked={item.checked}
                eventContext={Contexts.LINEAGE}
                eventObject="nodeFilterCheckbox"
                eventAction={Actions.CLICKED}
                eventProperties={{ filterItem: item.label[1] }}
              />
            ))}
          </>
        ))}
        <MenuDivider
          color="default"
          type="def"
          view="smooth"
        />
        <DefaultButton
          data-testid="connectToLookerButton"
          text={(
            <span style={{
              display: 'grid',
              placeItems: 'center',
              gridAutoFlow: 'column',
              gap: 8,
              gridTemplateColumns: '20px auto',
            }}
            >
              <Icon icon="inner-join" iconSize={20} color="var(--violet70)" />
              Connect integration
            </span>
          )}
          color="primary"
          view="smooth"
          fill
          onClick={() => history.push(`/settings/${AccountSettingsTab.INTEGRATIONS}`)}
          style={{
            margin: '2px 16px',
            width: 'calc(100% - 32px)',
          }}
          eventContext={Contexts.LINEAGE}
          eventObject="connectToLooker"
          eventAction={Actions.CLICKED}
        />
        <MenuDivider
          color="default"
          type="def"
          view="smooth"
        />
        <li
          style={{
            paddingBottom: 16,
            display: 'grid',
            placeItems: 'center',
            height: 'unset',
          }}
        >
          <DefaultButton
            data-testid="submitButton"
            text={submitText()}
            dense
            color="primary"
            view="filled"
            fill
            style={{
              width: 'calc(100% - 32px)',
            }}
            onClick={() => {
              handleFilterChange(
                setNodesFilter,
                nodeTypeChecksWithoutTitles(),
              );

              // close dropdown after user selects a filter
              const resetButton = document.querySelector('[data-testid="filterDropdown"]') as HTMLButtonElement;
              resetButton?.click();
            }}
            eventContext={Contexts.LINEAGE}
            eventObject="nodes"
            eventAction={Actions.FILTERED}
          />
        </li>
      </Menu>
    </div>
  );
};

export default NodeFilter;
