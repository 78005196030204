import { useEffect, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import {
  useScheduleCommandLogsSubscription,
} from '../../../client/generated/service-dataEndpoint';
import { useBoltCommandLazyQuery, GetScheduleRunQuery } from '../../../client/generated/service';

const useGetCommandLogs = (
  commandId: number,
  refreshData: () => Promise<ApolloQueryResult<GetScheduleRunQuery>>,
) => {
  const [outputFromQuery, setOutputFromQuery] = useState<string[]>([]);
  const [outputFromSubscription, setOutputFromSubscription] = useState<string[]>([]);
  const [commandIsFinished, setCommandIsFinished] = useState(false);

  const { data: subscriptionData } = useScheduleCommandLogsSubscription({
    variables: { commandID: commandId },
  });

  const processLogLines = (stdout: string, stderr: string) => {
    const logString = stdout.length > 0
      ? stdout
      : stderr;

    return logString.split('\n');
  };

  const [getCommandOutput, { loading: isLoadingCommandOutput }] = useBoltCommandLazyQuery({
    variables: { commandId },
    onCompleted: (data) => {
      if (data.boltCommand?.ok) {
        setOutputFromQuery(
          processLogLines(data.boltCommand.stdout, data.boltCommand.stderr),
        );
      }
    },
  });

  useEffect(() => {
    if (subscriptionData?.scheduleCommandLogs.stdout) {
      setOutputFromSubscription(
        processLogLines(subscriptionData.scheduleCommandLogs.stdout, ''),
      );
    }
    if (subscriptionData?.scheduleCommandLogs.finished !== undefined) {
      setCommandIsFinished(subscriptionData.scheduleCommandLogs.finished);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionData?.scheduleCommandLogs.finished) {
      setTimeout(refreshData, 500);
    }
  }, [subscriptionData]);

  return {
    isLoadingCommandOutput,
    getCommandOutput,
    commandOutput: outputFromSubscription.length > 0 ? outputFromSubscription : outputFromQuery,
    commandIsFinished,
  };
};

export { useGetCommandLogs };
