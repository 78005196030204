import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';

export interface useHighlightPageSectionProps {
  pageIsLoaded: boolean,
}

export const useHighlightPageSection = ({
  pageIsLoaded,
}: useHighlightPageSectionProps) => {
  const { state } = useLocation<{highlightId: string}>();

  const driverObj = driver({
    popoverClass: 'paradime-driver-theme',
    stagePadding: 0,
    onDestroyed: () => {
      const el = document?.activeElement as HTMLElement | undefined;
      el?.blur();
    },
  });

  useEffect(() => {
    const el = document.getElementById(state?.highlightId);
    if (state?.highlightId && pageIsLoaded) {
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      driverObj.highlight({ element: document.getElementById(state?.highlightId)! });
    }
  }, [pageIsLoaded]);
};
