import { useEffect, useState } from 'react';
import {
  Edge, Node, MarkerType,
} from 'reactflow';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { IconName } from '@blueprintjs/icons';
import { CommandType, ScheduleMetaType } from '../Scheduler/Insights/DAG';
import { RunResultsTabId } from '../Scheduler/LogsAndFreshness';
import { boltStore } from '../../../stores';
import { useGetNode } from './useGetNode';
import { ScheduleTriggerType, useGetUpstreamDownstreamTriggers } from './useGetUpstreamDownstreamTriggers';
import {
  useGetScheduleNameLazyQuery,
  GqlState,
} from '../../../client/generated/service';
import {
  getCommandNodeName,
  getScheduleNodeName,
  getScheduleNodeIcon,
} from '../utils';

const enum PositionConstants {
  NODE_XSTART = 0,
  NODE_YSTART = 50,
  NODE_XSTEP = 300,
  NODE_YSTEP = 0,
  EDGE_MARKER_WIDTH = 15,
  EDGE_MARKER_HEIGHT = 15,
  EDGE_MARKER_COLOR = '#19191d',
  EDGE_STROKE_WIDTH = 2,
  INITIAL_COMMANDS_TO_SKIP = 2,
}

export type CommandNodeType = {
  id: string,
  command: string,
  nodeType: string,
  color: colorType,
  icon: IconName,
}

const useGetNodes = (
  scheduleMeta?: ScheduleMetaType,
  commands?: (CommandType | null)[] | null,
) => {
  let parentNodeCount = 0;
  let allNodes: Node[] = [];
  let allEdges: Edge[] = [];

  const { getNode } = useGetNode();
  const [fetchScheduleData, { loading: loadingScheduleData }] = useGetScheduleNameLazyQuery();
  const {
    upstreamTrigger,
    downstreamTriggers,
  } = useGetUpstreamDownstreamTriggers(scheduleMeta?.scheduleNameName);

  const { setRunResultsTabId, setExpandCommandId } = boltStore.getState();
  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes] = useState<Node[]>();
  const [edges, setEdges] = useState<Edge[]>();

  const getScheduleData = async (scheduleNameUuid: string) => {
    try {
      const { data } = await fetchScheduleData({
        variables: {
          offset: 0,
          limit: 1,
          scheduleNameUuid,
        },
      });
      return data?.getScheduleName;
    } catch (error) {
      return null;
    }
  };

  const processUpstreamTrigger = async (targetUuid?: string) => {
    if (upstreamTrigger?.scheduleName && upstreamTrigger?.scheduleNameUuid) {
      parentNodeCount = 1;
      const parentNodeScheduleData = await getScheduleData(upstreamTrigger.scheduleNameUuid);
      const parentNodeState = parentNodeScheduleData?.state as GqlState;

      const parentNode = getNode({
        id: upstreamTrigger.scheduleName,
        name: getScheduleNodeName(upstreamTrigger),
        color: parentNodeState?.colorType as colorType,
        position: {
          x: PositionConstants.NODE_XSTART as number,
          y: PositionConstants.NODE_YSTART as number,
        },
        icon: getScheduleNodeIcon(parentNodeState?.colorType as colorType),
        iconColor: '',
        onClick: () => {
          const uri = `/bolt/${upstreamTrigger.scheduleNameUuid}`;
          window.open(uri, '_blank');
        },
      });

      const parentEdge = {
        id: '0',
        source: upstreamTrigger.scheduleName,
        target: targetUuid,
        style: {
          strokeWidth: PositionConstants.EDGE_STROKE_WIDTH,
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: PositionConstants.EDGE_MARKER_WIDTH,
          height: PositionConstants.EDGE_MARKER_HEIGHT,
          color: PositionConstants.EDGE_MARKER_COLOR,
        },
        animated: true,
      } as Edge;

      return {
        nodes: [parentNode],
        edges: [parentEdge],
      };
    }
    return { nodes: [], edges: [] };
  };

  const processDownstreamTriggers = async (
    triggers: ScheduleTriggerType[],
    commandNodesCount: number,
    sourceUuid?: string,
  ) => {
    const validTriggers = triggers.filter(
      (node) => node.scheduleNameUuid !== null && node.scheduleNameUuid !== undefined,
    );

    const scheduleDataPromises = validTriggers.map(
      (node) => getScheduleData(node.scheduleNameUuid!),
    );
    const scheduleDataResults = await Promise.all(scheduleDataPromises);

    const downstreamNodes = validTriggers.map((node, index) => (
      getNode({
        id: node.scheduleNameUuid!,
        name: getScheduleNodeName(node),
        color: scheduleDataResults[index]?.state.colorType as colorType,
        position: {
          x: (
            PositionConstants.NODE_XSTART
            + (parentNodeCount + commandNodesCount) * PositionConstants.NODE_XSTEP
          ),
          y: (
            PositionConstants.NODE_YSTART
            + (parentNodeCount + commandNodesCount + index) * PositionConstants.NODE_YSTEP
          ),
        },
        icon: getScheduleNodeIcon(scheduleDataResults[index]?.state.colorType as colorType),
        iconColor: '',
        onClick: () => {
          const uri = `/bolt/${node.scheduleNameUuid}`;
          window.open(uri, '_blank');
        },
      })
    ));

    const downstreamEdges = triggers
      .filter((node) => node.scheduleNameUuid !== null && node.scheduleNameUuid !== undefined)
      .map((node, index) => ({
        id: (parentNodeCount + commandNodesCount + index).toString(),
        source: sourceUuid,
        target: node.scheduleNameUuid!,
        style: {
          strokeWidth: PositionConstants.EDGE_STROKE_WIDTH,
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: PositionConstants.EDGE_MARKER_WIDTH,
          height: PositionConstants.EDGE_MARKER_HEIGHT,
          color: PositionConstants.EDGE_MARKER_COLOR,
        },
        animated: true,
      } as Edge));

    return { nodes: downstreamNodes, edges: downstreamEdges };
  };

  useEffect(() => {
    const fetchNodesAndEdges = async () => {
      setIsLoading(true);
      if (commands && downstreamTriggers) {
        // Process upstream trigger
        const upstream = await processUpstreamTrigger(
          commands.slice(PositionConstants.INITIAL_COMMANDS_TO_SKIP)[0]?.meta.uuid,
        );
        allNodes = [...upstream.nodes];
        allEdges = [...upstream.edges];

        const commandNodes = commands.slice(PositionConstants.INITIAL_COMMANDS_TO_SKIP)
          .filter((c) => c !== undefined && c !== null)
          .map((c, index) => (
            getNode({
              id: c!.meta.uuid,
              name: getCommandNodeName(c!.command),
              color: c!.state.colorType as colorType,
              position: {
                x: (
                  PositionConstants.NODE_XSTART
                  + (parentNodeCount + index) * PositionConstants.NODE_XSTEP
                ),
                y: PositionConstants.NODE_YSTART as number,
              },
              icon: getScheduleNodeIcon(c!.state.colorType as colorType),
              iconColor: '',
              onClick: () => {
                setRunResultsTabId(RunResultsTabId.LOGS);
                setExpandCommandId(c!.meta.id);
              },
            })
          ));

        const commandEdges = commands.slice(PositionConstants.INITIAL_COMMANDS_TO_SKIP, -1)
          .map((c, index) => ({
            id: (parentNodeCount + index).toString(),
            source: c?.meta.uuid,
            target: commands.slice(
              PositionConstants.INITIAL_COMMANDS_TO_SKIP,
            )[index + 1]?.meta.uuid,
            style: {
              strokeWidth: PositionConstants.EDGE_STROKE_WIDTH,
            },
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: PositionConstants.EDGE_MARKER_WIDTH,
              height: PositionConstants.EDGE_MARKER_HEIGHT,
              color: PositionConstants.EDGE_MARKER_COLOR,
            },
            animated: true,
          } as Edge));

        // add command nodes
        allNodes.push(...commandNodes);
        allEdges.push(...commandEdges);

        // Process downstream triggers
        const downstream = await processDownstreamTriggers(
          downstreamTriggers,
          commandNodes.length,
          commands.slice(
            PositionConstants.INITIAL_COMMANDS_TO_SKIP,
          ).slice(-1)[0]?.meta.uuid,
        );
        allNodes.push(...downstream.nodes);
        allEdges.push(...downstream.edges);

        setNodes(allNodes);
        setEdges(allEdges);
        setIsLoading(false);
      }
    };

    fetchNodesAndEdges();
  }, [commands, upstreamTrigger, downstreamTriggers]);

  return {
    isLoading: isLoading || loadingScheduleData,
    nodes,
    edges,
  };
};

export { useGetNodes };
