import React, {
  Dispatch, FC, SetStateAction, useEffect, useState,
} from 'react';
import { underline, lightGray } from 'ansicolor';
import CodeBox from '@paradime-io/pragma-ui-kit/lib/components/CodeBox';
import { ApolloQueryResult } from '@apollo/client';
import { GetScheduleRunQuery } from '../../../../client/generated/service';
import { parseStatsDbtLogs, RuntimeStatsProblemLineIndices } from './LogsParser';
import { useGetCommandLogs } from '../../hooks/useGetCommandLogs';
import CommandLogsHeader from './CommandLogsHeader';
import { useViewArtifact } from '../../hooks/useViewArtifact';

export interface ConsoleLogsProps {
  commandId: number,
  command: string,
  setCommandStats: Dispatch<SetStateAction<string | undefined>>,
  refreshData: () => Promise<ApolloQueryResult<GetScheduleRunQuery>>,
}

const LOG_LINES_LIMIT = 999;

const ConsoleLogs: FC<ConsoleLogsProps> = ({
  commandId,
  command,
  setCommandStats,
  refreshData,
}) => {
  const { viewArtifactSqlFromPath } = useViewArtifact();
  const [logLines, setLogLines] = useState<string[]>(['...loading console logs']);
  const {
    getCommandOutput,
    commandOutput,
    isLoadingCommandOutput,
  } = useGetCommandLogs(commandId, refreshData);

  const [problemLineIndices, setProblemLineIndices] = useState<RuntimeStatsProblemLineIndices>();
  const [
    currentVisibleConsoleLogsLineIndex, setCurrentVisibleConsoleLogsLineIndex,
  ] = useState<number>(0);
  const [
    previousVisibleConsoleLogsLineIndex, setPreviousVisibleConsoleLogsLineIndex,
  ] = useState<number>(0);
  const [highlightClass, setHighlightClass] = useState('highlightError');

  const runtimeStatsOutput = () => {
    const parsedData = parseStatsDbtLogs(commandOutput);
    if (parsedData.problemLines) {
      setProblemLineIndices(parsedData.problemLines);
    }

    if (parsedData.runtimeStats && parsedData.runtimeStats.total > 0) {
      return ([
        `${parsedData.runtimeStats.running} running`,
        `${parsedData.runtimeStats.pass} pass`,
        `${parsedData.runtimeStats.warn} warn`,
        `${parsedData.runtimeStats.error} error`,
        `${parsedData.runtimeStats.skip} skip`,
        `${parsedData.runtimeStats.total} total`,
      ].join(', '));
    }

    return undefined;
  };

  useEffect(() => {
    getCommandOutput();
  }, []);

  useEffect(() => {
    if (commandOutput.filter((cmd) => cmd.length > 0).length > 0) {
      const tempStats = runtimeStatsOutput();
      setCommandStats(tempStats);

      if (commandOutput.length > LOG_LINES_LIMIT) {
        setLogLines([
          ...commandOutput.slice(0, LOG_LINES_LIMIT),
          '*** Download for the full un-truncated logs ***',
        ]);
      } else {
        setLogLines(commandOutput);
      }
    } else {
      setLogLines(['No logs found for this command']);
    }
  }, [commandOutput]);

  return (
    <CodeBox
      backgroundColor="var(--grey100)"
      colorStep="0"
      lines={isLoadingCommandOutput ? ['... loading console logs'] : logLines}
      maxHeight={512}
      scrollToBottom
      style={{ paddingTop: 50 }}
      currentVisibleLineIndex={currentVisibleConsoleLogsLineIndex}
      onChangeCurrentVisibleLineIndex={setCurrentVisibleConsoleLogsLineIndex}
      highlightCurrentLine={{
        className: highlightClass,
        previousLine: previousVisibleConsoleLogsLineIndex,
        setPreviousLine: setPreviousVisibleConsoleLogsLineIndex,
      }}
      boxHeader={(
        <CommandLogsHeader
          command={command}
          logLines={commandOutput}
          problemLines={problemLineIndices}
          scrollToLineIndex={currentVisibleConsoleLogsLineIndex}
          setScrollToLineIndex={setCurrentVisibleConsoleLogsLineIndex}
          setPreviousScrollToLineIndex={setPreviousVisibleConsoleLogsLineIndex}
          setHighlightClass={setHighlightClass}
        />
                  )}
      spanClickHandlers={[
        {
          regex: /.*\s*compiled code at\s*(.*)/i,
          matchStyle: {
            cursor: 'pointer',
          },
          onMatch: (line: string, matchedText: string) => {
            const formattedMatchedText = lightGray(underline(matchedText));
            return line.replace(matchedText, formattedMatchedText);
          },
          onMatchClick: (compiledSqlPath: string) => viewArtifactSqlFromPath(
            commandId, compiledSqlPath,
          ),
        },
        {
          regex: /(.*No comparison.*)/i,
          matchStyle: {
            color: 'var(--red70)',
            backgroundColor: 'var(--red5)',
            borderRadius: '4px',
            padding: '2px 8px',
            animation: 'highlightFadeIn 300ms',
          },
          onMatch: (line: string, matchedText: string) => {
            const formattedMatchedText = matchedText;
            return line.replace(matchedText, formattedMatchedText);
          },
        },
      ]}
    />
  );
};

export default ConsoleLogs;
