import { TabId } from '@blueprintjs/core';
import create from 'zustand';
import { combine } from 'zustand/middleware';

export const appStore = create(
  combine(
    {
      showInviteUser: false,
      commandBarInitialized: false as boolean,
      showCatalogLineageFullscreen: false as boolean,
      launchDarklyIsReady: false,
      currentAppTabId: '' as TabId | undefined,
    },
    (set) => ({
      setCommandBarInitialized: (commandBarInitialized: boolean) => set(
        () => ({ commandBarInitialized }),
      ),
      setShowInviteUser: (show: boolean) => set(() => ({ showInviteUser: show })),
      setShowCatalogLineageFullscreen: (
        showCatalogLineageFullscreen: boolean,
      ) => set(() => ({ showCatalogLineageFullscreen })),
      setlaunchDarklyIsReady: (launchDarklyIsReady: boolean) => set(
        () => ({ launchDarklyIsReady }),
      ),
      setCurrentAppTabId: (currentAppTabId?: TabId) => set(() => ({ currentAppTabId })),
    }),
  ),
);
