import React, { FC, ReactNode } from 'react';
import { TabId } from '@blueprintjs/core';
import NavBar from '../../NavBar';
import * as S from './AppPage.styles';

export interface AppPageProps {
  children: ReactNode,
  isNavBarVisible?: boolean,
  currentApp?: string,
  currentAppTabId?: TabId,
}

const AppPage:FC<AppPageProps> = ({
  children,
  isNavBarVisible,
  currentApp,
  currentAppTabId,
}) => (
  <S.NavbarWrapper>
    {isNavBarVisible && (
      <NavBar
        currentApp={currentApp || ''}
        currentAppTabId={currentAppTabId}
      />
    )}
    <S.Body>{children}</S.Body>
  </S.NavbarWrapper>
);

export default AppPage;
